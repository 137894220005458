<template>
  <div id="printpoy" class="pagemodal-wrapper open">
    <div class="container">
      <div class="pagemodal">
        <div class="head bg-success">
          <i class="fas fa-check-double"></i> ส่งโพยสำเร็จ
          <a class="btn-close" @click="closePrint" style="cursor:pointer;"></a>
        </div>
        <div class="content">
          <div class="content-scroll p-2" style="padding-bottom: 8vh !important;display: block;overflow: auto;">
            <div class="bg-light border border-0 pb-1 mb-1" id="capture">
              <div class="row p-0 m-0">
                <div class="row p-0 m-0 col-12 bg-dark">
                  <div
                    class="
                      col-7
                      pl-2
                      py-1
                      d-flex
                      justify-content-start
                      align-items-center
                    "
                  >
                    <!-- <img
                      src="@/assets/wisdom/new_logo.png"
                      width="150"
                      class="align-middle"
                      style="width: 11vh; padding: 2vh 0"
                    /> -->
                  </div>
                  <div
                    class="col-5 pr-2 py-1 pl-0 text-white text-right"
                    style="line-height: 1"
                  >
                    <small class="text-secondary">โพยเลขที่</small><br />
                    <h4 class="hbill">
                      <small>#</small> <span id="poy_id">{{getRecords.length > 0 ? getRecords[0].ticket_id : ''}}</span>
                    </h4>
                  </div>
                </div>
                <div class="col-8 pl-2 pt-2">
                  <table class="table table-sm table-responsive mb-1">
                    <tbody>
                      <tr>
                        <td colspan="2" class="border-top-0">
                          <h5 class="hhuay bet_name"> {{ data.group_name }} </h5>
                        </td>
                      </tr>
                      <tr class="border">
                        <td class="table-secondary">
                          <i class="far fa-clock"></i>
                        </td>
                        <td>
                          <b class="hhuay_round bet_round">{{ data.title ? data.title : '' }}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <span class="text-success"
                    ><i class="fas fa-vote-yea"></i> ส่งโพยสำเร็จ</span
                  >
                </div>
                <div class="col-4 pl-0 pt-2 text-right">
                  <small>ทำรายการเมื่อ</small>
                  <table class="table table-sm table-bordered">
                    <tbody>
                      <tr>
                        <td class="text-left table-secondary">วันที่</td>
                            <td class="text-left">{{ getToday() }}</td>
                      </tr>
                      <tr>
                        <td class="text-left table-secondary">เวลา</td>
                          <td class="text-left">{{ getTime() }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  class="
                    col-12
                    p-2
                    mb-0
                    text-center
                    table-success
                    border border-success border-right-0 border-left-0
                  "
                  style="transition: none"
                >
                  <span>ยอดรวม :</span>
                  <span class="font-weight-bold text-success thb total_price"
                      v-html="numberWithCommas(onSum()) + ' ฿'"
                    ></span
                  >
                </div>
                <div class="col-12 pt-3 pb-0 mb-0" id="pl_success">
                  <p v-if="isShowMsgWarnning()" class="m-0 p-0 text-danger "> *** ราคาจ่ายมีการเปลี่ยนแปลง </p>
                  <div
                    class="card card-huay"
                    id="model_pre_confirm"
                    v-for="(item, index) in (numberList ? numberList : [])"
                    :key="index"
                  >
                    <div class="card-header card-header-sm">
                      <div class="row">
                        <div class="col-4 font-weight-bold text-success">
                          {{ item.title }}
                        </div>
                        <div class="col-4 text-right">ราคาซื้อ</div>
                        <div class="col-4 text-right">ราคาจ่าย</div>
                      </div>
                    </div>
                    <div class="card-body card-body-sm">
                      <div
                        class="row"
                        id="pl_confim_teng_bon_3"
                        v-for="(obj, key) in item.list"
                        :key="key"
                      >
                        <div class="col-4">{{ key + 1 }}. {{ obj.number }}</div>
                        <div class="col-4 text-right" v-html="`${ numberWithCommas(obj.price) || 0}฿`"></div>
                        <div v-bind:class="{'text-danger': obj.isChange , 'font-weight-bold': obj.isChange, 'blink_me': obj.isChange }" class="col-4 text-right" v-html="`${ (obj.isChange ? '***' : '' ) } ${ numberWithCommas(obj.pay) || 0}฿`"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 mt-0 mb-2">
                  <div
                    class="
                      alert alert-success
                      p-1
                      px-2
                      mb-1
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                  >
                    <span
                      ><i class="fas fa-user-check"></i>{{ getUsername }}</span
                    >
                    <span class="badge badge-secondary">© {{ AGENT_S_BASE }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="fixbot p-2">
            <div class="row px-3 mt-3">
              <div class="col-6 p-0">
                <router-link
                to="/member/poy"
                  class="btn btn-light btn-block border rounded-0 doo_poy"
                >
                  <i class="fas fa-history doo_poy"></i> ประวัติโพย
                </router-link>
              </div>
              <div class="col-6 p-0">
                <button
                  class="btn btn-primary btn-block rounded-0"
                  @click="closePrint"
                >
                  <i class="fas fa-plus"></i> แทงต่อ
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  props: {
    data: {
      default: {}
    }
  },
  data: () => {
    return {
      result: {
        roundID: null,
        type: [],
        number: [],
        price: []
      },
      AGENT_S_BASE: process.env.VUE_APP_AGENT_BASE,
      numberList: [],
      indexType: ['สามตัวบน', 'สามตัวโต๊ด', 'สามตัวหน้า', 'สามตัวล่าง', 'สองตัวบน', 'สองตัวล่าง', 'วิ่งบน', 'วิ่งล่าง']
    }
  },
  methods: {
    ...mapActions(['setNumberList', 'getBalanceInfo', 'setLoading']),
    async closePrint () {
      await this.setLoading(true)
      await this.getBalanceInfo()
      this.setNumberList([])
      window.scrollTo(0, 0)
      this.$emit('closePrint', false)
      await this.setLoading(false)
    },
    onSum () {
      return this.numberList.reduce(
        (c, e) => c + e.list.reduce((a, b) => a + parseInt(b.price), 0),
        0
      )
    },
    isShowMsgWarnning () {
      return this.numberList.reduce(
        (c, e) => c | e.list.reduce((a, b) => a | b.isChange, false),
        false
      )
    },
    getToday () {
      const timer = this.$moment()
      const day = timer.format('DD')
      const month = timer.format('MM')
      const years = timer.format('YYYY')
      return `${day}/${month}/${years}`
    },
    getTime () {
      const timer = new Date()
      const min = timer.getMinutes().toString()
      const hour = timer.getHours().toString()
      const pad = '00'
      const minCal = pad.substring(0, pad.length - min.length) + min
      const hourCal = pad.substring(0, pad.length - hour.length) + hour

      return `${hourCal}:${minCal}`
    },
    numberWithCommas (x) {
      return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  created () {
    this.result.roundID = parseInt(this.$route.params.id)
    this.getRecords.map(e => {
      if (this.numberList.find(d => d.indexN === e.typeN)) {
        this.numberList.find(d => d.indexN === e.typeN).list.push(e)
      } else {
        this.numberList.push({
          title: '',
          indexN: e.typeN,
          list: [e]
        })
      }
    })
    this.indexType.map((e, index) => {
      if (this.numberList.find(d => d.indexN === index)) {
        this.numberList.find(d => d.indexN === index).title = e
      }
    })
  },
  computed: {
    ...mapState({
      titleBet: state => state.betModules.titleBet
    }),
    ...mapGetters(['getRecords', 'getUsername', 'getNumberList', 'getInquiryRoundInfomation'])
  }
}
</script>

<style>
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
