<template>
  <div id="price" class="pagemodal-wrapper">
    <div class="container">
      <div class="pagemodal">
        <div class="head">
          <i class="fas fa-edit"></i> ใส่ราคา
          <span class="badge badge-pill badge-dark" id="total_poy_list"
            >{{ this.getNumberList.flatMap((e) => e.list).length }} รายการ</span
          ><a
            class="btn-close triggerPrice"
            style="cursor: pointer"
            @click="exitPrice()"
          ></a>
        </div>
        <table
          class="table table-striped table-hover table-sm d-none"
          id="model_poy_option"
        >
          <tbody>
            <tr>
              <th class="align-middle">#</th>
              <th colspan="2" class="text-danger" style="font-size: 16px">
                {bet-option-name}
              </th>
              <th class="text-center text-primary" style="font-size: 16px">
                จ่าย
              </th>
              <th colspan="2" class="text-dark" style="font-size: 16px">
                เรทชนะ
              </th>
            </tr>
            <tr id="model_poy_list" class="{is-duplicate-class}">
              <td class="align-middle text-center table-secondary">
                {bet_cnt}.
              </td>
              <td
                class="
                  align-middle
                  text-center
                  bg-success
                  text-white
                  font-weight-bold
                  {is-duplicate-class}
                "
                style="font-size: 16px"
              >
                {pl_number}
              </td>
              <td class="align-middle text-center">
                <input
                  type="tel"
                  id="pl_price_{poy-list-id}"
                  value="{pl-price}"
                  placeholder="ระบุจำนวนเงิน"
                  minlength="1"
                  maxlength="6"
                  pattern="[0-9]*"
                  class="
                    form-control
                    bg-black
                    text-gold
                    border-right-gold
                    pl_price
                  "
                  oninput="this.value=this.value.replace(/[^0-9]/g,'')"
                />
              </td>
              <td class="align-middle text-center" id="model_poy_list_multiply">
                {bet_multiply}
              </td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  id="model_poy_list_win"
                  value="{ Number(pl-win).toLocaleString('en') }฿"
                  readonly=""
                />
              </td>
              <td>
                <div class="btn btn-danger" id="del_pl_{poy-list-id}">
                  <i class="fas fa-trash-alt"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="content" style="">
          <div class="content-scroll">
            <span
              id="poy_list"
              v-for="(item, index) in getNumberList"
              :key="index"
              ><table
                class="table table-hover table-sm"
                id="poy_option_teng_bon_3"
              >
                <tbody>
                  <tr>
                    <th class="align-middle">#</th>
                    <th colspan="2" class="text-danger" style="font-size: 16px">
                      {{ item.title }}
                    </th>
                    <th
                      class="text-center text-primary"
                      style="font-size: 16px"
                    >
                      จ่าย
                    </th>
                    <th colspan="2" class="text-dark" style="font-size: 16px">
                      เรทชนะ
                    </th>
                  </tr>
                  <tr
                    id="pl_0"
                    class=""
                    v-for="(numbers, key) in item.list"
                    :key="key"
                    v-bind:class="{ bg_darksalmon: isLimit(numbers, item.data.index) }"
                  >
                    <td
                      class="align-middle text-center table-secondary"
                      :class="onDuplicate(index, key)"
                    >
                      {{ key + 1 }}.
                    </td>
                    <td
                      class="
                        align-middle
                        text-center
                        bg-success
                        text-white
                        font-weight-bold
                      "
                      style="font-size: 16px"
                    >
                      {{ numbers }}
                    </td>
                    <td
                      class="align-middle text-center"
                      :class="onDuplicate(index, key)"
                    >
                      <input
                        type="tel"
                        value="1"
                        v-model="item.cal[key]"
                        placeholder="ระบุจำนวนเงิน"
                        :min="item.data.min"
                        :max="getMaxPrice (item.data.min ,item.data.max , item.data.maxPerNum, item.data.index, item.list[key], index, key) "
                        minlength="1"
                        maxlength="6"
                        pattern="[0-9]*"
                        class="
                          form-control
                          bg-black
                          text-gold
                          border-right-gold
                          pl_price
                        "
                        @change="updateMyValue($event,index,key,item, numbers, item.data.index)"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'')"
                      />
                    </td>
                    <td
                      class="align-middle text-center"
                      :class="onDuplicate(index, key)"
                    >
                      {{ onCalLimitPrice(item, numbers, item.data.index) }}
                    </td>
                    <td :class="onDuplicate(index, key)">
                      <input
                        type="text"
                        class="form-control"
                        :value="`${ Number((onCalLimitPrice(item, numbers, item.data.index) * item.cal[key])).toLocaleString('en') || 0}฿`"
                        readonly=""
                      />
                    </td>
                    <td :class="onDuplicate(index, key)">
                      <button
                        class="btn btn-danger"
                        @click="onDeleteNumber(index, key)"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody></table
            ></span>
          </div>
          <div class="fixbot" style="">
            <div class="row px-3">
              <div class="col-6 ml-0">
                <button
                  class="btn btn-secondary btn-block"
                  @click="onViewDuplicateNumber()"
                >
                  <i class="fas fa-search"></i> ดูเลขซ้ำ
                </button>
              </div>
              <div class="col-6 mr-0">
                <button
                  class="btn btn-dark btn-block"
                  id="delete_duplicate"
                  @click="onRemoveDuplicateNumber()"
                >
                  <i class="fas fa-eraser"></i> ตัดเลขซ้ำ
                </button>
              </div>
            </div>
            <hr class="mb-0" />
            <div class="row px-3">
              <div
                class="
                  col-6
                  align-middle
                  text-center text-success
                  pl-4
                  pt-2
                  pr-0
                "
              >
                ราคา<b>"เท่ากัน"</b>ทั้งหมด<br />
                <div class="input-group mb-3">
                  <input
                    type="tel"
                    class="form-control"
                    placeholder="ใส่ราคา"
                    aria-label="ใส่ราคา"
                    aria-describedby="button-addon2"
                    id="set_all_price"
                    oninput="this.value=this.value.replace(/[^0-9]/g,'')"
                    v-model="samePrice"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-success"
                      type="button"
                      id="button-addon2"
                      @click="pickAllPrice(samePrice)"
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-6 align-middle text-center pr-1 pl-0">
                <div
                  class="
                    box__chip-lists
                    d-flex
                    justify-content-around
                    align-items-center
                    flex-wrap
                    p-2
                  "
                >
                  <div class="price" data-id="5" @click="pickAllPrice(5)">
                    5 ฿
                  </div>
                  <div class="price" data-id="10" @click="pickAllPrice(10)">
                    10 ฿
                  </div>
                  <div class="price" data-id="20" @click="pickAllPrice(20)">
                    20 ฿
                  </div>
                  <div class="price" data-id="50" @click="pickAllPrice(50)">
                    50 ฿
                  </div>
                  <div class="price" data-id="100" @click="pickAllPrice(100)">
                    100 ฿
                  </div>
                </div>
              </div>
            </div>
            <div class="row px-3 pb-1 mb-1">
              <div class="col-6 bg-primary text-white text-center p-1 mb-3">
                <span class="badge badge-light">ยอดเครดิตคงเหลือ</span>
                <br /><span class="thb" data-id="credit_balance"
                  >{{ getBalance }} ฿</span
                >
              </div>
              <div class="col-6 bg-danger text-white text-center p-1 mb-3">
                <span class="badge badge-light">รวมยอดแทง</span><br /><span
                  class="thb total_price"
                  v-html="onSum() ? numberWithCommas(onSum()) + ' ฿' : '-'"
                ></span>
              </div>
              <div class="col-6">
                <button
                  class="btn btn-light btn-block"
                  id="reset_poy"
                  data-toggle="modal"
                  data-target="#modal_confirm_reset"
                >
                  <i class="fas fa-ban"></i> ยกเลิกทั้งหมด
                </button>
              </div>
              <div class="col-6">
                <button
                  class="btn btn-success btn-block triggerSendpoy"
                  @click="openSend"
                >
                  <i class="fas fa-check"></i> ส่งโพย
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    limits: {
      type: Array,
      default: () => []
    },
    sumNumber: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      samePrice: '',
      valueInputs: [],
      duplicate: [],
      isShow: false,
      newLimit: [],
      modelValue: 0
    }
  },
  async created () {
    this.newLimit = this.limits
    const setInput = this.getNumberList.map((e, index) => {
      return {
        ...e,
        cal: e.list.map((_) => e.data.min),
        pay: e.list.map((numbers, key) => Number(this.onCalLimitPrice(e, numbers, e.data.index) * this.getPrice(1, e.data.min, e.data.max)))
      }
    })
    this.setNumberList(setInput)
    this.duplicate = this.getNumberList
  },
  watch: {
    samePrice: function (value) {
      this.pickAllPrice(Number(value))
    }
  },
  computed: {
    ...mapGetters(['getNumberList', 'getBalance', 'getVip', 'getIsVip'])
  },
  methods: {
    ...mapActions(['setNumberList']),
    updateMyValue (event, index, key, item, numbers, itemDataIndex) {
      const myValue = event.target.value.trim() // Formatting example
      if (event.target.value === '' || Number(myValue) < Number(event.target.min) || Number(myValue) > Number(event.target.max)) {
        const newPrice = this.getPrice(myValue, event.target.min, event.target.max)
        this.$set(this.getNumberList[index].cal, key, newPrice)
      }
      this.$set(this.getNumberList[index].pay, key, Number(this.getNumberList[index].cal[key] * this.onCalLimitPrice(item, numbers, itemDataIndex)))
    },
    numberWithCommas (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    onSum () {
      return this.getNumberList.reduce(
        (c, e) => c + e.cal.reduce((a, b) => a + parseInt(b), 0),
        0
      )
    },
    isLimit (numbers, index) {
      return this.limits ? (this.limits.filter(e => (Number(e.num) === Number(numbers) && Number(e.index) === Number(index))).length > 0) : false
    },
    exitPrice () {
      this.$emit('exitPrice', false)
    },
    openSend () {
      this.$emit('openSend', true)
    },
    onDeleteNumber (index, key) {
      this.getNumberList[index].list.splice(key, 1)
      this.getNumberList[index].cal.splice(key, 1)
      this.getNumberList[index].pay.splice(key, 1)
      if (this.getNumberList[index].list.length === 0) {
        this.getNumberList.splice(index, 1)
      }
    },
    onDuplicate (index, key) {
      return this.duplicate[index]?.list[key]
        ? ''
        : 'number-is-duplicate number-is-duplicate-active'
    },
    onCalLimitPrice (item, number, index) {
      if (!this.limits?.length) {
        return this.getStringNumber(Number(item.data.price) + Number(item.data.vipPrice[this.getVip]))
      } else if (this.limits.filter(e => (Number(e.num) === Number(number) && Number(e.index) === Number(index))).length === 0) {
        return this.getStringNumber(Number(item.data.price) + Number(item.data.vipPrice[this.getVip]))
      }
      const tempLimit = this.limits.filter(e => ((Number(e.index) === Number(index)) && (Number(e.num) === Number(number))))
      return this.getStringNumber(Number(tempLimit[0].price) + Number(item.data.vipPrice[this.getVip]))
    },
    getStringNumber (Value) {
      if ((Number(Value) % 1) > 0) {
        return Number(Value).toLocaleString('en')
      } else {
        return Value
      }
    },
    onViewDuplicateNumber () {
      if (this.isShow) {
        this.isShow = false
        this.duplicate = this.getNumberList
        return
      }
      this.duplicate = this.getNumberList.map((e) => {
        return {
          ...e,
          list: e.list.filter((c, index) => e.list.indexOf(c) === index)
        }
      })
      this.isShow = true
    },
    onRemoveDuplicateNumber () {
      const duplicate = this.getNumberList.map((e) => {
        return {
          ...e,
          cal: e.cal.filter(
            (d, key) =>
              !e.list
                .map((c, index) => (e.list.indexOf(c) === index ? null : index))
                .find((x) => x === key)
          ),
          list: e.list.filter((c, index) => e.list.indexOf(c) === index)
        }
      })
      this.setNumberList(duplicate)
      this.duplicate = this.getNumberList
    },
    pickAllPrice (value) {
      this.samePrice = value
      const setPrice = this.getNumberList.map((e, indexArr) => {
        return {
          ...e,
          cal: e.list.map((c, keyArr) => this.getPrice(value, e.data.min, this.getMaxPrice(e.data.min, e.data.max, e.data.maxPerNum, e.data.index, e.list[keyArr], indexArr, keyArr)))
        }
      })
      this.setNumberList(setPrice)
    },
    getPrice (value, min, max) {
      if (Number(value) < Number(min)) {
        return min.toString()
      } else if (Number(value) > Number(max)) {
        return max.toString()
      } else {
        return value.toString()
      }
    },
    getMaxPrice (min, max, maxPerNum, indexNum, number, indexArr, keyArr) {
      let sumAll = this.sumNumber.filter(e => ((Number(e.index) === Number(indexNum)) && (e.number === number))).reduce((partial_sum, a) => partial_sum + Number(a.sum), 0)
      let newMax = max
      if (sumAll >= maxPerNum) {
        sumAll = 0
        this.onDeleteNumber(indexArr, keyArr)
        this.$bvToast.toast('จำกัดราคาซื้อสูงสุด/เลข/งวด', {
          noCloseButton: true,
          autoHideDelay: 1000,
          variant: 'danger',
          solid: false
        })
      } else {
        newMax = maxPerNum - sumAll
        if (newMax > max) {
          newMax = max
        }
      }
      return newMax
    }
  }
}
</script>

<style scoped>
.price {
  cursor: pointer;
}
.modal-dialog {
  z-index: 100;
}
.bg_darksalmon, .bg_darksalmon:hover{
  background: lightcoral !important;
}
</style>
