<template>
  <div id="sendpoy" class="pagemodal-wrapper open">
    <div class="container">
      <div class="pagemodal">
        <div class="head bg-success">
          <i class="fas fa-check-circle"></i> ยืนยันคำสั่งซื้อ
          <a class="btn-close triggerSendpoy" @click="closeSend()" style="cursor: pointer"></a>
        </div>
        <div class="content">
          <div class="content-scroll p-2" style="padding-bottom: 8vh !important;display: block;overflow: auto;">
            <div class="border pb-2 mb-2">
              <div class="row p-0 m-0">
                <div class="col-7 py-1 bg-dark d-flex align-items-center">
                  <!-- <img
                    src="@/assets/wisdom/new_logo.png"
                    width="150"
                    class="align-middle"
                    style="width: 11vh; padding: 2vh 0"
                  /> -->
                </div>
                <div class="col-5 py-1 pl-0 bg-dark text-white d-flex justify-content-end align-items-center">
                  <h4 class="hbill mb-0">ใบสั่งซื้อ</h4>
                </div>
                <div class="col-8 pl-2 pt-2">
                  <table class="table table-sm table-responsive">
                    <tbody>
                      <tr>
                        <td colspan="2" class="border-top-0">
                          <h5 class="hhuay bet_name" id="pre_confirm_name">
                            {{ data.group_name }}
                          </h5>
                        </td>
                      </tr>
                      <tr class="border">
                        <td class="table-secondary">
                          <i class="far fa-clock"></i>
                        </td>
                        <td>
                          <b class="hhuay_round bet_round" id="pre_confirm_round">
                            {{ data.title ? data.title : '' }}
                            </b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-4 pl-0 pt-2">
                  <table class="table table-sm table-bordered">
                    <tbody>
                      <tr>
                        <td class="text-left table-secondary">วันที่</td>
                        <td class="text-left">{{ getToday() }}</td>
                      </tr>
                      <tr>
                        <td class="text-left table-secondary">เวลา</td>
                        <td class="text-left">{{ getTime() }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-12 py-2 text-center table-success border border-success border-right-0 border-left-0">
                  <span>ยอดเดิมพันทั้งหมด : </span>
                  <span class="font-weight-bold text-success thb total_price" v-html=" numberWithCommas(onSum()) + ' ฿'"></span>
                </div>
                <div class="card card-huay d-none" id="model_pre_confirm">
                  <div class="card-header card-header-sm">
                    <div class="row">
                      <div class="col-7 font-weight-bold text-success">{bet-name}</div>
                      <div class="col-5 text-right">ราคา</div>
                    </div>
                  </div>
                  <div class="card-body card-body-sm">
                    <div class="row" id="{pl_confirm_op}">
                      <span class="d-none" id="model_pre_confirm_list">
                        <div class="col-3">{bet-cnt}.</div>
                        <div class="col-4">{bet-number}</div>
                        <div class="col-5 text-right thb">฿ 0</div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 py-3" id="pl_confirm">
              <div class="card card-huay" id="model_pre_confirm" v-for="(item, index) in getNumberList" :key="index">
                <div class="card-header card-header-sm">
                  <div class="row">
                    <div class="col-6 font-weight-bold text-success">
                      {{ item.title }}
                    </div>
                    <div class="col-6 text-right">ราคาซื้อ</div>
                  </div>
                </div>
                <div class="card-body card-body-sm">
                  <div class="row" id="pl_confim_teng_bon_3" v-for="(numbers, key) in item.list" :key="key">
                    <div class="col-6">{{ key + 1 }}. {{ numbers }}</div>
                    <div class="col-6 text-right" v-html="`${ numberWithCommas(item.cal[key]) || 0}฿`"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="fixbot p-2" style="">
            <hr class="mb-2" />
            <div class="row">
              <div class="col-6">
                <button class="btn btn-light btn-block triggerSendpoy" @click="closeSend()">
                  <i class="fas fa-chevron-left"></i> กลับไปแก้ไข
                </button>
              </div>
              <div class="col-6">
                <button class="btn btn-success btn-block successbet triggerPrintpoy" @click="openPrint()">
                  <i class="fas fa-check"></i> ยืนยันการส่งโพย
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import * as numeral from 'numeral'

export default {
  props: {
    data: {
      default: {}
    }
  },
  data: () => {
    return {
      result: {
        roundID: null,
        type: [],
        number: [],
        price: [],
        pay: []
      },
      priceAll: 0,
      btn_enable: true
    }
  },
  created () {
    this.result.roundID = parseInt(this.$route.params.id)
  },
  computed: {
    ...mapState({
      titleBet: state => state.betModules.titleBet
    }),
    ...mapGetters(['getNumberList', 'getBalance'])
  },
  methods: {
    ...mapActions(['setNumberList', 'submitNumberLotto', 'openErrorPopup', 'setLoading']),
    closeSend () {
      this.$emit('closeSend', false)
    },
    numberWithCommas (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    async openPrint () {
      if (this.btn_enable === true) {
        this.btn_enable = false
        this.getNumberList.map((e, i) => {
          e.list.map(d => {
            this.result.number.push(d)
            this.result.type.push(e.data.index)
          })
          e.cal.map(c => {
            this.result.price.push(parseInt(c))
          })
          e.pay.map(c => {
            this.result.pay.push(parseInt(c))
          })
        })
        this.priceAll = this.result.price.reduce((a, b) => a + b, 0)
        if (this.priceAll < 0) {
          this.openErrorPopup('ยอดแทงไม่เพียงพอ')
        }
        if (this.priceAll <= numeral(this.getBalance)._value) {
          this.setLoading(true)
          const resp = await this.submitNumberLotto(this.result)
          this.setLoading(false)
          if (resp.status_code === 'SUCCESS') {
            this.$emit('openPrint', true)
          }
        } else {
          this.openErrorPopup('เครดิตของท่านไม่เพียงพอ')
        }
        this.btn_enable = true
      }
    },
    onSum () {
      return this.getNumberList.reduce((c, e) => c + e.cal.reduce((a, b) => a + parseInt(b), 0), 0)
    },
    getToday () {
      const timer = this.$moment()
      const day = timer.format('DD')
      const month = timer.format('MM')
      const years = timer.format('YYYY')
      return `${day}/${month}/${years}`
    },
    getTime () {
      const timer = new Date()
      const min = timer.getMinutes().toString()
      const hour = timer.getHours().toString()
      const pad = '00'
      const minCal = pad.substring(0, pad.length - min.length) + min
      const hourCal = pad.substring(0, pad.length - hour.length) + hour

      return `${hourCal}:${minCal}`
    }
  }
}
</script>

<style>
</style>
