<template>
  <div class="box__condition-info">
    <h2 class="text-primary"><i class="fas fa-exclamation-circle"></i> เงื่อนไขการแทง</h2>
    <div class="row">
      <div class="col-md-12" id="content_teng_bon_1" v-for="(item, index) in lists.filter(e => e.enable)" :key="index">
        <h3>{{item.title}} จ่าย : {{item.price}}</h3>
        <p>
          แทงขั้นต่ำต่อครั้ง : {{ numberWithCommas(item.min) }}<br />
          แทงสูงสุดต่อครั้ง : {{ numberWithCommas(item.max) }}<br />
          แทงสูงสุดต่อเลข : {{ numberWithCommas(item.maxPerNum) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listSelector: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    listSelector: function (val) {
      this.lists = val
    }
  },
  data () {
    return {
      lists: []
    }
  },
  methods: {
    numberWithCommas (n) {
      const x = Number(n)
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
}
</script>

<style>
</style>
