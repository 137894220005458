<template>
  <div class="modal fade" id="modal_confirm_reset" tabindex="-1" role="dialog" style="display: none" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-white">ข้อความแจ้งเตือน</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <p class="text-white">คุณแน่ใจนะว่าต้องการลบ เลขทั้งหมด ?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">ปิด</button>
          <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closePrice()">ลบทั้งหมด</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  methods: {
    ...mapActions(['setNumberList']),
    closePrice () {
      this.setNumberList([])
      this.$emit('closePrice', false)
    }
  }
}
</script>

<style>
</style>
