<template>
  <div id="poy" class="pagemodal-wrapper open">
    <div class="container">
      <div class="pagemodal">
        <div class="head">
          <i class="fas fa-list-ol"></i> เลขชุด - ดึงโพย
          <a class="btn-close triggerPoy" style="cursor: pointer" @click="$emit('closeSet', false)"></a>
        </div>
        <div class="content">
          <div class="content-scroll">
            <ul id="poytab" class="nav nav-tabs" role="tablist">
              <li class="nav-item w-50 text-center">
                <a
                  class="nav-link mysetnumber active show"
                  href="#poy1"
                  role="tab"
                  data-toggle="tab"
                  aria-selected="true"
                  @click="getSet"
                  >เลขชุด</a
                >
              </li>
              <li class="nav-item w-50 text-center">
                <a
                  class="nav-link mypoy"
                  href="#poy2"
                  role="tab"
                  data-toggle="tab"
                  aria-selected="false"
                  @click="getPoy"
                  >ดึงโพย</a
                >
              </li>
            </ul>

            <div class="tab-content">
              <div role="tabpanel" class="tab-pane text-center active" id="poy1">
                <div class="col-12 numberset-grid justify-content-between mb-2">
                  <router-link to="/member/numbersets" tag="button" class="btn btn-primary btn-sm rounded-0"
                    ><i class="fas fa-edit"></i> จัดการเลขชุด</router-link
                  >
                  <router-link to="/member/numbersets/add" tag="button" class="btn btn-success btn-sm rounded-0"
                    ><i class="fas fa-plus-square"></i> สร้างเลขชุด</router-link
                  >
                </div>

                <table class="table table-striped table-sm" id="numfavorite">
                  <tbody>
                    <tr class="bg-info text-light">
                      <th class="align-middle text-center">ชื่อชุด</th>
                      <th class="align-middle text-center">เวลาที่สร้าง</th>
                      <th class="align-middle text-center">เลข</th>
                      <th class="align-middle text-right"></th>
                    </tr>
                    <tr class="" id="model_set_number" v-for="(item, index) in listSetNumber" :key="index">
                      <td>{{ item.title }}</td>
                      <td>{{ item.date_create }}</td>
                      <td>{{ getNum(item.num_json) }}</td>
                      <td class="text-right">
                        <a
                          href="#"
                          @click="$emit('sendSet', calculateToPushNumber(item.num_json))"
                          class="btn btn-outline-success btn-sm"
                          ><i class="fas fa-check"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div role="tabpanel" class="tab-pane" id="poy2">
                <table class="table table-striped table-hover table-sm" style="table-layout: fixed" id="mynumpoy">
                  <tbody>
                    <tr class="bg-dark text-light">
                      <th class="align-middle text-center">โพย</th>
                      <th class="align-middle text-center">เวลาที่แทง</th>
                      <th class="align-middle text-center">เลข</th>
                      <th class="align-middle text-right"></th>
                    </tr>
                    <tr class="" id="model_pull_poy" v-for="(item, index) in listPoy" :key="index">
                      <td>{{ item.TypeBet }}</td>
                      <td>{{ item.dateBetting }}</td>
                      <td style="overflow: hidden; text-overflow: ellipsis">
                        {{ getNum(item.numberbet) }}
                      </td>
                      <td class="text-right">
                        <a
                          v-show="getNum(item.numberbet)"
                          href="#"
                          @click="$emit('sendSet', calculateToPushNumber(item.numberbet))"
                          class="btn btn-outline-success btn-sm"
                          ><i class="fas fa-check"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNumberSet, listAllBetHistoryServiceFull } from '@/services'
import { mapActions } from 'vuex'
import { flatten } from '@/utils/'
export default {
  async created () {
    await this.getSet()
    await this.getPoy()
  },
  computed: {},
  methods: {
    ...mapActions(['setLoading']),
    getNum (numArr) {
      const numStr = ''
      if (!numArr?.length) {
        return numStr
      }
      return flatten(numArr.map(e => e.list)).join(', ')
    },
    async getSet () {
      try {
        this.setLoading(true)
        const resp = await getNumberSet()
        if (resp.status_code === 'SUCCESS') {
          this.listSetNumber = resp.data.listSetNumber
        }
        this.setLoading(false)
      } catch (error) {
        this.setLoading(false)
      }
    },
    async getPoy () {
      try {
        this.setLoading(true)
        const resp = await listAllBetHistoryServiceFull()
        if (resp.status_code === 'SUCCESS') {
          this.listPoy = resp.data
        }
        this.setLoading(false)
      } catch (error) {
        this.setLoading(false)
      }
    },
    calculateToPushNumber (numbers) {
      if (!numbers?.length) {
        return
      }
      return numbers
        .map(number => {
          if (!number?.list?.length) {
            return undefined
          }
          return { key: number.name, numbers: number.list.map(String) }
        })
        .filter(Boolean)
    }
  },
  data () {
    return {
      pageActive: true,
      listSetNumber: [],
      listPoy: [],
      indexType: ['สามตัวบน', 'สามตัวโต๊ด', 'สามตัวหน้า', 'สามตัวล่าง', 'สองตัวบน', 'สองตัวล่าง', 'วิ่งบน', 'วิ่งล่าง']
    }
  }
}
</script>

<style scoped>
.numberset-grid {
  display: inline-grid;
  /* grid-template-columns: 120px 110px 1fr; */
  grid-template-columns: 1fr 1fr;
  padding: 0 !important;
}
</style>
